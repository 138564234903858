
import Vue from "vue";
import { mdiFileDocument, mdiCashRefund, mdiChevronDown, mdiChevronUp, mdiCash, mdiClose, mdiCheck } from "@mdi/js";
import AppInput from "@/components/appInput.vue";
import Boton from "@/components/botones/boton.vue";
import { Filtro, Operacion, Kiosko, EstatusPago, Pago, EstatusOperacion } from "apd.models";
import { formatISO } from "date-fns";
import { ApiErrorResponse, ApiList, ApiSistemaPagos, AxiosHttpClient, getUrl } from "apd.apiconnectors";
import selectoresModule from "@/store/modules/selectoresModule";
import spinnerModule from "@/store/modules/spinnerModule";
import errorSnackbar from "@/store/modules/errorSnackbar";
import { Guid } from "guid-typescript";

export default Vue.extend({
  name: "PagarCnd",
  components: {
    AppInput,
    Boton,
  },
  data: () => ({
    icons: {
      folio: mdiFileDocument,
      refund: mdiCashRefund,
      collapse: mdiChevronUp,
      expand: mdiChevronDown,
      cash: mdiCash,
      close: mdiClose,
      check: mdiCheck,
    } as Record<string, string>,
    folioApd: "" as string,
    filtro: {
      rfc: undefined,
      email: undefined,
      referenciaPago: undefined,
      folioAutorizacion: undefined,
      folioAPD: "",
      telefono: undefined,
      limitar: undefined,
      autorizado: undefined,
      idEmpresa: undefined,
      idServicio: undefined,
      idFormaPago: undefined,
      municipios: [],
      estatusOperacion: [EstatusOperacion.Aplicada, EstatusOperacion.Cobrada, EstatusOperacion.Cancelada],
      kioskos: [],
      fechaInicial: "2020-01-01",
      fechaFinal: formatISO(Date.now()).substr(0, 10),
    } as Filtro,
    expanded: [],
    singleExpand: false,
    loading: false,
    dialog: false,
    headersCnd: [
      { id: 1, text: "FolioAPD", value: "folioAPD", align: "start" },
      { id: 2, text: "Referencia", value: "referenciaPago", align: "start" },
      { id: 3, text: "Cajero", value: "idKiosko", align: "start" },
      { id: 4, text: "Importe", value: "importeServicio", align: "end" },
      { id: 5, text: "Pagar", value: "pagar", align: "center" },
    ],
    headersPagos: [
      { id: 1, text: "Cantidad", value: "cantidad", align: "end" },
      { id: 2, text: "Denominación", value: "denominacion", align: "end" },
      { id: 3, text: "Importe", value: "monto", align: "end" },
      { id: 4, text: "Estatus", value: "estatus", align: "start" },
    ],
    operacionArray: [] as Operacion[],
    cajerosArray: [] as Kiosko[],
    pagosCndArray: [] as Pago[],
    operacionCnd: {} as Operacion,
    cndPagados: [] as Operacion[],
  }),
  computed: {
    isDark(): boolean {
      return this.$vuetify.theme.dark;
    },
    getNombreCajero() {
      return (value: string): string | undefined => {
        return selectoresModule.cajeros.find((c) => c.id == value)?.nombrePublico;
      };
    },
    estatusPagoToString() {
      return (value: number): string => {
        return EstatusPago[value];
      };
    },
    totalCnd() {
      return this.pagosCndArray.reduce((p, i) => p + Number(i.monto), 0);
    },
    disableBtnBuscar() {
      return this.filtro.folioAPD != "" && this.filtro.folioAPD != null && this.filtro.folioAPD != undefined
        ? false
        : true;
    },
    disableBtnPagar() {
      const user = this.$store.getters["oidcStore/oidcUser"];

      if (user["user_type"] != "Empleado") {
        const cajeros = this.cajerosArray.filter((c) => c.idEmpresa == user["RSP.Empresa"]);

        if (cajeros.some((c) => c.id == this.operacionCnd.idKiosko)) {
          return false;
        }
        return true;
      }
      return true;
    },
    cndPagado() {
      return (value: string): boolean => {
        if (this.cndPagados.some((o) => o.id == value)) {
          return true;
        }
        return false;
      };
    },
  },
  async mounted() {
    if (selectoresModule.cajeros.length <= 0) {
      await selectoresModule.LoadCajeros();
    }
    this.cajerosArray = selectoresModule.cajeros;
  },
  methods: {
    async loadBtn() {
      spinnerModule.Show();
      this.loading = true;
      this.operacionArray = [];
      this.cndPagados = [];
      const clientSP = new AxiosHttpClient(getUrl(ApiList.SistemaPagos, this.$store.getters.appMode));
      clientSP.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
      const apiSP = new ApiSistemaPagos(clientSP);
      await apiSP
        .ConsultarOperacionesFiltro(this.filtro)
        .then((e) => {
          this.operacionArray = e as Operacion[];
          this.operacionArray.forEach((op) => {
            apiSP.ValidarNoDispensado(op.id).then((e) => {
              if (e > 0) {
                this.cndPagados.push(op);
              }
            });
          });
        })
        .catch((e) => {
          const x = e as ApiErrorResponse;
          x.apiError.mensajeUsuario;
          errorSnackbar.Show(x.apiError.mensajeUsuario);
        });
      this.loading = false;
      spinnerModule.Hide();
    },
    cargarCnd(id: string) {
      this.loading = true;
      this.dialog = true;
      this.pagosCndArray = [];
      const operacion = this.operacionArray.filter((operacion) => operacion.id == id);
      this.operacionCnd = operacion[0];

      if (this.operacionCnd.pagos != undefined) {
        this.operacionCnd.pagos
          .filter((p) => p.estatusPago == EstatusPago.NoDispensado)
          .forEach((p) => {
            p.estatusPago = EstatusPago.Dispensado;
            p.id = Guid.create().toString();
            p.formaPago = undefined;
            this.pagosCndArray.push(p);
          });
      }
      this.loading = false;
    },
    async pagarCnd() {
      this.loading = true;
      spinnerModule.Show();
      const clientSP = new AxiosHttpClient(getUrl(ApiList.SistemaPagos, this.$store.getters.appMode));
      clientSP.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
      const apiSP = new ApiSistemaPagos(clientSP);
      const idUsuario = this.$store.getters["oidcStore/oidcUser"].sub;

      const cajero =
        this.$store.getters.appMode == "production"
          ? "90f6c99f-54ec-4041-b465-000000000004"
          : "8ca45794-50ce-49a6-8204-6052725e64f0";

      await apiSP
        .PagarNoDispensado(this.operacionCnd.id, cajero, this.pagosCndArray, idUsuario)
        .then(() => {
          this.dialog = false;
        })
        .catch((e) => {
          const x = e as ApiErrorResponse;
          x.apiError.mensajeUsuario;
          errorSnackbar.Show(x.apiError.mensajeUsuario);
        })
        .finally(() => {
          this.loadBtn();
        });

      spinnerModule.Hide();
      this.loading = false;
    },
  },
});
